<template>
  <div class="registration">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"/>

    <RegistrationStep
      :step="0"/>

    <section id="registration_choose">
      <div class="w1000">
        <h5 class="txt-bold txt-center">
          {{$t('selectList')}}
          <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower">
        </h5>

        <div class="subject_box">
          <router-link to="/registrationchoose/1" class="subject">
            <div class="icon" style="background-color: #E5A9B9;">
              <img src="@/assets/img/Online_Registration/icon1.svg" alt="">
            </div>
            <p class="txt-dark_gray">{{$t('nuwaIvf')}}</p>
          </router-link>
          <router-link to="/registrationchoose/2" class="subject">
            <div class="icon" style="background-color: #E5A9B9;">
              <img src="@/assets/img/Online_Registration/icon1.svg" alt="">
            </div>
            <p class="txt-dark_gray">{{$t('nuwaTcm')}}</p>
          </router-link>
          <div class="subject" @click="openLightbox">
            <div class="icon" style="background-color: #E5A9B9;">
              <img src="@/assets/img/Online_Registration/icon1.svg" alt="">
            </div>
            <p class="txt-dark_gray">{{$t('specialOutpatient')}}</p>
          </div>
        </div>

        <p class="small txt-dark_gray txt-center">{{$t('OutpatientInfo')}}</p>
      </div>
    </section>

    <section id="registration_note">
      <div class="w1000">
        <div class="note_block">
          <p class="note_title txt-bold">
            {{$t('selectList')}}
            <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower">
          </p>
          <div class="note_registration_time">
            <h6>{{$t('amOutpatient')}} | <span class="txt-theme">08:30-12:00</span></h6>
            <h6>{{$t('noonOutpatient')}} | <span class="txt-theme">14:00-17:00</span></h6>
            <h6>{{$t('nightOutpatient')}} | <span class="txt-theme">18:00-21:00</span></h6>
          </div>
          <p class="small">{{$t('bookingInfo')}}</p>
        </div>
        <div class="note_block">
          <p class="note_title txt-bold">
            {{$t('bookingInfoPrecaution')}}
            <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower">
          </p>
          <ol class="numList">
            <li class="small">
              {{$t('bookingInfoPrecautionContent1')}}
            </li>
            <li class="small">
              {{$t('bookingInfoPrecautionContent2')}}
            </li>
            <li class="small">
              {{$t('bookingInfoPrecautionContent3')}}
            </li>
            <li class="small">
              {{$t('bookingInfoPrecautionContent4')}}
            </li>
            <li class="small">
              {{$t('bookingInfoPrecautionContent5_1')}}
              <br />
              {{$t('bookingInfoPrecautionContent5_2')}}
              <br />
              {{$t('bookingInfoPrecautionContent5_3')}}
              <br />
              {{$t('bookingInfoPrecautionContent5_4')}}
              <br />
              {{$t('bookingInfoPrecautionContent5_5')}}
              <br />
            </li>
          </ol><br>
          <p class="txt-theme small">
            {{$t('bookingInfoPrecautionContent6_1')}}
            <br />
            {{$t('bookingInfoPrecautionContent6_2')}}
          </p>
        </div>
        <div class="note_block">
          <p class="note_title txt-bold">
            {{$t('clinicCallNumberRule')}}
            <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower">
          </p>
          <ul class="note_list">
            <li class="small">
              {{$t('clinicCallNumberContent1')}}
            </li>
            <li class="small">
              {{$t('clinicCallNumberContent2')}}
            </li>
            <li class="small">
              {{$t('clinicCallNumberContent3')}}
            </li>
          </ul>
        </div>
        <!-- 新增 -->
        <div class="note_block">
          <p class="note_title txt-bold">
            {{$t('clinicTcmNotify')}}
            <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower">
          </p>
          <ul class="note_list">
            <li class="small">
              {{$t('clinicTcmNotifyContent_1')}}
            </li>
            <li class="small">
              {{$t('clinicTcmNotifyContent_2')}}
            </li>
            <li class="small">
              {{$t('clinicTcmNotifyContent_3')}}
            </li>
            <li class="small">
              {{$t('clinicTcmNotifyContent_4')}}
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- <div id="change_password_lightbox"
      v-show="changePasswordLightboxOpen">
      <div class="cover" @click="changePasswordLightboxOpen = false;"></div> -->
    <div id="registration_lightbox2" v-show="ifLightbox">
      <div class="cover" @click="closeLightbox"></div>
      <div class="box">
        <img src="@/assets/img/delete.svg" class="close"  @click="closeLightbox">
        <div class="top txt-center"
          :style="{backgroundImage: `url(${require('@/assets/img/temp/a_banner.jpg')})`}">
          <h5 class="txt-bold">{{$t('nuwaSpecialOutpatient')}}</h5>
          <h5>VIP Outpatient service <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt=""></h5>
          <br>
          <p class="small">
            {{$t('nuwaSpecialOutpatientInfo_1')}}
            <br>
            {{$t('nuwaSpecialOutpatientInfo_2')}}
          </p>
        </div>
        <div class="sec">
          <div class="inner">
            <div class="col40">
              <h5 class="txt-bold">
                {{$t('clinicServiceTitle')}}
                <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
              </h5>
            </div>
            <div class="col60">
              <p class="small">
                {{$t('clinicServiceContent')}}
              </p>
            </div>
          </div>
        </div>
        <div class="tri">
          <div class="inner">
            <div class="txt-center col100">
              <h5 class="txt-bold">
                {{$t('phoneBooking')}}
                <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
              </h5>
              <br>
              <p class="small">
                {{$t('phoneBookingContent')}}
              </p>
            </div>
          </div>
          <div class="stepouter">
            <div class="pic"
              :style="{backgroundImage: `url(${stepPic[nowstep-1]})`}"></div>
            <div class="stepbox">
              <div class="step" :class="{active : nowstep == 1}" @click="nowstep=1">
                <p class="small">{{$t('personReception')}}</p>
                <p class="in small">{{$t('personReceptionContent')}}</p>
              </div>
              <div class="step" :class="{active : nowstep == 2}" @click="nowstep=2">
                <p class="small">{{$t('vipSpace')}}</p>
                <p class="in small">{{$t('vipSpaceContent')}}</p>
              </div>
              <div class="step" :class="{active : nowstep == 3}" @click="nowstep=3">
                <p class="small">{{$t('accompany')}}</p>
                <p class="in small">{{$t('accompanyContent')}}</p>
              </div>
              <div class="step" :class="{active : nowstep == 4}" @click="nowstep=4">
                <p class="small">{{$t('personReception')}}</p>
                <p class="in small">{{$t('personReceptionContent_2')}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="four">
          <div class="inner">
            <div>
              <p class="small txt-bold">
                {{$t('clinicRule')}}
              </p>
              <ul class="small">
                <li>{{$t('clinicRuleContent1')}}</li>
                <li>{{$t('clinicRuleContent2')}}</li>
                <li>{{$t('clinicRuleContent3')}}</li>
                <li>{{$t('clinicRuleContent4')}}</li>
              </ul>
              <div class="line">
                <p class="small txt-bold">{{$t('specialClinicPhoneNumber')}}</p>
              </div>
              <p class="txt-center txt-theme">
                {{$t('local')}} (02)2706-6152 | {{$t('oversea')}} (886)2-2706-6152
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/registration.scss';
import BannerKv from '@/components/BannerKv.vue';
import RegistrationStep from '@/components/registration/RegistrationStep.vue';

import pic01 from '@/assets/img/index/imglink_1.jpg';
import qwe1 from '@/assets/img/temp/qwe1.jpg';
import qwe2 from '@/assets/img/temp/qwe2.jpg';
import qwe3 from '@/assets/img/temp/qwe3.jpg';
import qwe4 from '@/assets/img/temp/qwe4.jpg';

export default {
  name: 'Registration',
  components: {
    BannerKv,
    RegistrationStep,
  },
  data() {
    return {
      BannerKvData: {
        titleEn: 'ONLINE REGISTRATION',
        titleZh: this.$t('onlineBooking'),
        backgroundPic: pic01,
      },
      ifLightbox: false,
      nowstep: 1,
      stepPic: [qwe1, qwe2, qwe3, qwe4],
    };
  },
  methods: {
    openLightbox() {
      this.ifLightbox = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox() {
      this.ifLightbox = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
  },
};
</script>
